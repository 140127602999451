<template>

	<!-- Authors Table Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24">
					<h5 class="font-semibold m-0 text-center">Сезон 2022-2023</h5>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
					<a-radio-group v-model="authorsHeaderBtns" size="small" v-if="false">
						<a-radio-button value="all">ALL</a-radio-button>
						<a-radio-button value="online">ONLINE</a-radio-button>
					</a-radio-group>
				</a-col>
			</a-row>
		</template>
		<a-config-provider>
			<template #renderEmpty>
				<a-empty
					:image="simpleImage"
					description="Нет данных"
				/>
			</template>

			<a-table
				:columns="columns"
				:data-source="data"
				:pagination="false"
			>


				<template slot="player" slot-scope="player">
					<div class="table-avatar-info">
						<div class="avatar-info">
							<h6>{{ player }}</h6>
						</div>
					</div>
				</template>

				<template slot="goals" slot-scope="goals">
					<div class="goals">
						{{ goals }}
					</div>
				</template>

				<template slot="pass" slot-scope="pass">
					<div class="pass">
						{{ pass }}
					</div>
				</template>

				<template slot="editBtn" slot-scope="row">
					<a-button type="link" :data-id="row.key" class="btn-edit">
						Edit
					</a-button>
				</template>

			</a-table>
		</a-config-provider>
	</a-card>
	<!-- / Authors Table Card -->

</template>

<script>
	import { Empty } from 'ant-design-vue';
	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				// Active button for the "Authors" table's card header radio button group.
				authorsHeaderBtns: 'all',
			}
		},
		beforeCreate() {
			this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
		},
	})

</script>