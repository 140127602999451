<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div class="stats">

		<img class="logo" src="images/logos/logo.png" alt="Logo Ultras">
		<!-- Authors Table -->
		<a-row type="flex" >

			<!-- Authors Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Authors Table Card -->
				<CardAuthorTable
					:data="table1Data"
					:columns="table1Columns"
				/>
				<!-- / Authors Table Card -->

			</a-col>
			<!-- / Authors Table Column -->

		</a-row>
		<!-- / Authors Table -->

		<!-- Projects Table -->
		<a-row type="flex">

			<!-- Projects Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Projects Table Column -->
				<CardProjectTable2
					:data="table2Data"
					:columns="table2Columns"
					v-if="false"
				/>
				<!-- / Projects Table Column -->

			</a-col>
			<!-- / Projects Table Column -->

		</a-row>
		<!-- / Projects Table -->

		</div>
</template>

<script>

	// "Authors" table component.
	import CardAuthorTable from '../components/Cards/CardAuthorTable' ;

	// "Projects" table component.
	import CardProjectTable2 from '../components/Cards/CardProjectTable2' ;

	import axios from 'axios';
	
	// "Authors" table list of columns and their properties.
	const table1Columns = [
		{
			title: 'Игрок',
			dataIndex: 'player',
			scopedSlots: { customRender: 'player' },
		},
		{
			title: 'Голы',
			dataIndex: 'goals',
			scopedSlots: { customRender: 'goals' },
			class: 'goals',
		},
		{
			title: 'Пасы',
			dataIndex: 'pass',
			scopedSlots: { customRender: 'pass' },
			class: 'pass',
		},
		{
			title: 'Очки',
			dataIndex: 'total',
			class: 'total',
		},
	];

	export default ({
		components: {
			CardAuthorTable,
			CardProjectTable2,
		},
		data() {
			return {
				table1Data: [],
				table1Columns,
			}
		},
		mounted() {
			this.getPlayers();

		},
		methods: {
			getPlayers() {
				const data = {
					method: 'get-stat'
				}

				axios
					.post('https://hcultras.kz/api/', JSON.stringify(data))
					.then(response => {
						if (response.status === 200) {
							const players = response.data.stats;
							players.forEach((stats, index) => {
								this.table1Data.push({
									key: index + 1,
									player: stats.player,
									goals: stats.goals,
									pass: stats.pass,
									total: stats.total,
								});
							});
						}
					})
					.catch(error => console.log(error));
			}
		}
	})

</script>

<style lang="scss" >
	.avatar-info {
		display: flex;
		align-items: center;
	}

	.ant-table-column-title {
		text-transform: uppercase;
	}

	.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
		 padding: 16px;
	}
	.ant-layout-content {
		padding: 0;
		margin: 0 20px 0;
	}
	.goals,
	.pass,
	.total {
		text-align: center;
	}
	.total {
		font-weight: 700;
	}

	.ant-table-row:not(:hover) {
		.goals,
		.total {
			background: #fcfcfc;
		}
	}

	.stats {
		margin-top: 20px;
	}

	.ant-card {
		overflow: hidden;
	}

	.logo {
		width: 80px;
		height: auto;
		margin: 20px auto;
		display: flex;
		opacity: .7;
	}
</style>